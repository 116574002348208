/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(156, 156, 156);
    border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(90, 90, 90);
}

.card-img {
    height: 90px !important;
    width: 100% !important;
    object-fit: cover;
    border-radius: 5px;
}

.my-info-container{
    background-color: white;
    height: 610px;
    padding: 30px;
    min-width: 400px;
    box-shadow: 0px 5px 5px #aaa, 5px 2px 5px rgba(170, 170, 170, 0.767);
    border-radius: 7px 7px 7px 0px;
    z-index: 2;
}

mgt-person-card  {
    width: 100%;
    box-shadow: none;
}   

.employee-menu-icon{
    position: absolute;
    top: 5px;
    right: 0px;
}