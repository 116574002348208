.form-container{
    background-color: #eee;
    border-radius: 10px;
    max-height: 60vh;
    width: 50vw;
    padding: 5vh 5vw;
    overflow: auto;
}

.customer-form-container{
    border-radius: 10px;
    max-height: 60vh;
    width: 50vw;
    padding: 5vh 5vw;
    overflow: auto;
}

.col-l{
    width: 100%;
}

.file-name-form{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
}

.file-name-form > p{
    width: 80%;
    margin: auto;
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
}

.buttons {
    display: flex;
    justify-content: flex-end;
}

.button {
    margin-top: 24px !important;
    margin-left: 8px !important;
}

.stepper {
    padding-bottom: 30px;
    padding-top: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.close {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 30px;
    font-family: 'Courier New';
    cursor: pointer;
}

.layout {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    top: 0px;
    height: calc(100vh - 65px);
}

.paper {
    padding: 16px;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}