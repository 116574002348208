.alert-window-container {
    width: 470px;
    height: 400px;
    background-color: rgb(255,248,250);
    margin: auto;
    border-radius: 4px;
    position: relative;
    top: 15vh;
}

.alert-container {
    height: 100%;
    width: 100%;
    position: relative;
}

.alert-close-btn {
    position: absolute;
    top: 3px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

.alert-footer{
    position: absolute;
    bottom: 35px;
    width: calc(100% - 140px);
    text-align: justify;
}