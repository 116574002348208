.basic-info-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 30px;
}

.basic-info-text-container {
    text-align: start;
}

.profile-img-container {
    height: 80px;
    width: 80px;
    margin: auto;
    margin-right: 20px;
    border-radius: 100%;
    position: relative;
    cursor: pointer;
}

.profile-img-container-no-cursor {
    height: 80px;
    width: 80px;
    margin: auto;
    margin-right: 20px;
    border-radius: 100%;
}

.profile-img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
    border-radius: 100%;
}

.profile-img-dark-cover {
    height: 100% !important;
    width: 100% !important;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.274);
    position:absolute;
}

.display-none {
    display: none;
}

.profile-img-icon {
    position: absolute;
    bottom: -6px;
    right: -3px;
    display: block;
    color: rgb(61, 61, 61);
}

.additional-info-container {
    margin-top: 20px;
}